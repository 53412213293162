(function($) {

	'use strict';

     // Hero slider
    var swiper = new Swiper(".hero-slider .swiper-container", {
        loop: true,
        speed: 1000,
        effect: 'slide',
        slidesPerView: 1,
        autoplay: {
            delay: 6000,
            disableOnInteraction: false,
        },
        pagination: false,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        }
    });

    // Testimonial Slider
    var swiper = new Swiper(".section-quotes .swiper-container", {
        direction: "horizontal",
        loop: true,
        effect: 'slide',
        centeredSlides: false,
        slidesPerView: 1,
        spaceBetween: 40,
        pagination: false,
        breakpoints: {
            640: {
                slidesPerView: 2,
                spaceBetween: 40,
            },
            992: {
                slidesPerView: 3,
                spaceBetween: 40,
            }
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
        }
    });

    // Scroll based on hash, only if hash is present in the URL
    if (window.location.hash) {
        var hash = window.location.hash.substring(1); // Get hash without "#"
        if (hash) {
            // Ensure the element exists on the page before attempting to scroll
            var targetElement = $('#' + hash);
            if (targetElement.length) {
                var targetOffset = targetElement.offset().top - $('.site-header .navbar').height();
                
                // Smooth scroll to target element
                $('html, body').animate({ scrollTop: targetOffset }, 'fast');

                // Replace the current hash in the URL without reloading or triggering the same scroll again
                history.replaceState(null, document.title, window.location.pathname + window.location.search);
            }
        }
    }

    // Scroll to accordian
    $('.panel-group').on('shown.bs.collapse', function() {
        var panel = $(this).find('.in');
        $('html, body').animate({
            scrollTop: panel.offset().top - ($('.site-header').height() + 115)
        }, 'slow');
    });

	// Load background image from data attribute
    loadBackground();
    $(window).on('resize orientationchange', loadBackground);
    function loadBackground() {
        const $backgroundElements = $('[data-background]');
        
        // Exit early if no background elements
        if ($backgroundElements.length === 0) return;

        const isMobile = window.matchMedia("(max-width: 767px)").matches; // Detect mobile devices

        $backgroundElements.each(function() {
            const $this = $(this);
            const $background = $this.data('background'); // Use data() to get the background value
            const $backgroundMobile = $this.data('backgroundMobile'); // Use data() for mobile background

            // If the background is a color (starts with #), apply it as a background-color
            if ($background && $background.charAt(0) === '#') {
                $this.css('background-color', $background);
            }
            // If the device is mobile and a mobile background exists, apply the mobile background
            else if (isMobile && $backgroundMobile) {
                $this.css('background-image', `url(${ $backgroundMobile })`);
            }
            // Otherwise, apply the regular background image
            else if ($background) {
                $this.css('background-image', `url(${ $background })`);
            }
        });
    }
	
	// Counter
	$('.counter span').counterUp({
        delay: 10,
        time: 1000
    });
	
	//Scroll to Top
	$(window).scroll(function () {
		if ($(this).scrollTop() >= 100) {
			$('.scroll-to-top').fadeIn(200);
		} else {
			$('.scroll-to-top').fadeOut(200);
		}
	});
	$('.scroll-to-top').click(function () {
		$('body,html').animate({
			scrollTop: 0
		}, 500);
	});
	
    // Get current URL path and assign 'active' class
    var url = window.location.href.split('#')[0]; // Use the full URL minus the hash part for comparison

    // Loop through each link in the navigation
    $('.nav > li > a').each(function () {
        var linkUrl = this.href.split('#')[0]; // Remove hash part of the link
        if (url === linkUrl) {
            $(this).parent().addClass('active'); // For main menu items
        }
    });

    // Handle the dropdown menu by checking for "specialities" part in the URL path
    $('.nav a').each(function () {
        var linkUrl = this.href.split('#')[0]; // Strip hash from the link URL
        var dropdown = $(this).closest('.dropdown'); // Get the parent dropdown

        // If the link belongs to "Specialities" section or its children
        if (linkUrl.indexOf('specialities') > -1) {
            if (url.indexOf(linkUrl) > -1) {
                dropdown.addClass('active'); // Add 'active' class to the dropdown
            }
        }
    });

	/* Contact form */
    $('#form-contact').validate({
        rules: {
            'fc-name': {
                required: true
            },
            'fc-email': {
                required: true,
                email: true
            },
            'fc-phone': {
                required: true,
                digits: true
            },
            'fc-subject': {
                required: true
            }
        },
        highlight: function (element) {
            const $elementParent = $(element).parent();
            if (!$elementParent.hasClass('has-error')) {
                $elementParent.removeClass('has-success').addClass('has-error');
            }
        },
        unhighlight: function (element) {
            const $elementParent = $(element).parent();
            if (!$elementParent.hasClass('has-success')) {
                $elementParent.removeClass('has-error').addClass('has-success');
            }
        },
        errorElement: 'span',
        errorClass: 'help-block',
        errorPlacement: function (error, element) {
            // You can define custom error placement here if needed
        },
        submitHandler: function (form) {
            const $form = $(form);
            const submitBtn = $form.find("button[type=submit]");
            const submitBtnText = submitBtn.html();

            // Disable the submit button and show the waiting text
            submitBtn.html("Please wait...").attr('disabled', true);

            $.ajax({
                url: form.action,
                type: form.method,
                dataType: "json",
                data: $form.serialize(),
                success: function (response) {
                    // Re-enable the submit button and restore the original text
                    submitBtn.html(submitBtnText).attr('disabled', false);

                    // Show the response message
                    $('#result-contact').html(`
                        <div class="alert alert-${response.type}">
                            <a href="#" class="close" data-dismiss="alert" aria-label="close" title="close">×</a>
                            ${response.message}
                        </div>
                    `);

                    // Reset the form if the submission is successful
                    if (response.type === "success") {
                        $form.find('.has-success').removeClass('has-success');
                        $form[0].reset();
                    }
                },
                error: function () {
                    // Optionally handle errors, such as showing a generic error message
                    submitBtn.html(submitBtnText).attr('disabled', false);
                    $('#result-contact').html(`
                        <div class="alert alert-danger">
                            <a href="#" class="close" data-dismiss="alert" aria-label="close" title="close">×</a>
                            Something went wrong. Please try again.
                        </div>
                    `);
                }
            });
        }
    });
})(jQuery);