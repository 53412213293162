+ function($) {
    'use strict';

    // DROPDOWN CLASS DEFINITION
    // =========================

    var backdrop = '.dropdown-backdrop';

    var Hover = function(element, options) {
        this.options = options;
        this.$element = $(element);

        var that = this;

        // defining if navigation tree or single dropdown
        if (this.$element.hasClass('dropdown-toggle')) {
            this.dropdowns = this.$element.parent().find('.dropdown-menu').parent('.dropdown');
        } else {
            this.dropdowns = this.$element.find('.dropdown');
        }

        // this.dropdowns contains all "li" elements which have a submenu

        // this.options contains a onClick property which contains that the actual browser fires touch events, but this isn't used

        this.dropdowns.each(function() {

            $(this).children('a, button').on('touchstart', function(e) {

                // console.log('Hover - options.onClick - touchstart');

                $(this).attr('data-touchstart-event', 'true');

            }).on('click', function(e) {

                // console.log('Hover - options.onClick - click', e);

                var touchstartEvent = $(this).attr('data-touchstart-event');

                if (touchstartEvent === 'true') {

                    var isActive = $(this).parent().hasClass('open');
                    var showMenu = $(this).attr('data-show-menu');
                    if (!isActive && showMenu !== 'true') {
                        that.show($(this));
                        e.preventDefault();

                        // Hack: Stop immediate to disable all followed event handlers, to stop that bootstrap offcanvas
                        // can interfer with the menu. This will also stop all further event handler.
                        e.stopImmediatePropagation();

                    } else {
                        var href = $(this).attr('href');
                        if (!href) {
                            that.hide($(this));
                        }
                    }

                    // console.log('Hover - options.onClick - click - touched', e);
                }

            });

            $(this).on('mouseenter.bs.hover', function(e) {

                // console.log('Hover - !options.onClick - mouseenter.bs.hover', e.target);

                that.show($(this).children('a, button'))

            }).on('mouseleave.bs.hover', function(e) {

                // console.log('Hover - !options.onClick - mouseleave.bs.hover', e.target);

                that.hide($(this).children('a, button'))

            })
        })

    };

    Hover.TRANSITION_DURATION = 300;
    Hover.DELAY_SHOW = 100;
    Hover.DELAY_HIDE = 100;
    Hover.TIMEOUT_SHOW;
    Hover.TIMEOUT_HIDE;

    Hover.DEFAULTS = {
        onClick: false,
        animations: [
            'fadeIn',
            'fadeIn',
            'fadeIn',
            'fadeIn'
        ]
    };

    /**
     * @param $this
     *
     * @returns {*}
     */
    function getParent($this) {
        var selector = $this.attr('data-target');

        if (!selector) {
            selector = $this.attr('href');
            selector = selector && /#[A-Za-z]/.test(selector) && selector.replace(/.*(?=#[^\s]*$)/, '') // strip for ie7
        }

        var $parent = selector && $(document).find(selector);

        return $parent && $parent.length ? $parent : $this.parent()
    }

    /**
     * @param e
     */
    function clearMenus(e) {
        if (e && e.which === 3) {
            return;
        }

        $(backdrop).remove();
        $('[data-hover="dropdown"], .navbar-nav').each(function() {
            var $this = $(this);
            var $parent = getParent($this);
            var relatedTarget = {
                relatedTarget: this
            };

            if (!$parent.hasClass('open')) {
                return;
            }

            if (
                e &&
                e.type === 'click' &&
                /input|textarea/i.test(e.target.tagName) &&
                $.contains($parent[0], e.target)
            ) {
                return;
            }

            $parent.trigger(e = $.Event('hide.bs.hover', relatedTarget));

            if (e.isDefaultPrevented()) {
                return;
            }

            $this.attr('aria-expanded', 'false');
            $parent.removeClass('open').trigger($.Event('hidden.bs.hover', relatedTarget))
        })
    }

    /**
     * Opens dropdown menu when mouse is over the trigger element.
     *
     * @param _dropdownLink
     *
     * @returns {boolean}
     */
    Hover.prototype.show = function(_dropdownLink) {

        var that = this;
        var $this = $(_dropdownLink);

        window.clearTimeout(Hover.TIMEOUT_HIDE);

        Hover.TIMEOUT_SHOW = window.setTimeout(function() {

            // close all dropdowns
            $('.dropdown').not($this.parents()).each(function() {
                $(this).removeClass('open')
            });

            var effect = that.options.animations[0];

            if ($this.is('.disabled, :disabled')) {
                return;
            }

            var $parent = $this.parent();
            var isActive = $parent.hasClass('open');

            if (!isActive) {

                if ('ontouchstart' in document.documentElement && $parent.closest('.navbar-nav').length > 0) {

                    // if mobile we use a backdrop because click events don't delegate
                    //
                    // it will also be append to the dom multiple times, but if you click on the menu entry the site
                    // will be reloaded or if you click outsite of the menu all dom elements will be removed
                    $(document.createElement('div'))
                        .addClass('dropdown-backdrop')
                        .on('click', clearMenus)
                        .appendTo('body');
                }

                var $dropdown = $this.next('.dropdown-menu');

                $parent.addClass('open');
                $this.attr('aria-expanded', true);

                // Ensures that all menus that are closed have proper aria tagging.
                $parent.siblings().each(function() {
                    if (!$(that).hasClass('open')) {
                        $(that).find('[data-hover="dropdown"], .navbar-nav').attr('aria-expanded', false);
                    }
                });

                var side = that.position($dropdown);

                switch (side) {
                    case 'top':
                        effect = that.options.animations[2];
                        break;
                    case 'right':
                        effect = that.options.animations[3];
                        break;
                    case 'left':
                        effect = that.options.animations[1];
                        break;
                    default:
                        effect = that.options.animations[0];
                        break;
                }

                $dropdown.addClass('animated ' + effect);

                var transition = $.support.transition && $dropdown.hasClass('animated');

                transition ?
                    $dropdown
                    .one('bsTransitionEnd', function() {
                        $dropdown.removeClass('animated ' + effect)
                    })
                    .emulateTransitionEnd(Hover.TRANSITION_DURATION) :
                    $dropdown.removeClass('animated ' + effect)
            }

        }, Hover.DELAY_SHOW);

        return false
    };

    /**
     * Closes dropdown menu when mouse is out of it.
     *
     * @param _dropdownLink
     */
    Hover.prototype.hide = function(_dropdownLink) {

        var $this = $(_dropdownLink);
        var $parent = $this.parent();

        window.clearTimeout(Hover.TIMEOUT_SHOW);

        Hover.TIMEOUT_HIDE = window.setTimeout(function() {

            $parent.removeClass('open');
            $this.attr('aria-expanded', false)

        }, Hover.DELAY_HIDE);
    };

    /**
     * Calculating position of dropdown menu.
     *
     * @param dropdown
     *
     * @returns {string}
     */
    Hover.prototype.position = function(dropdown) {

        var win = $(window);

        // reset css to prevent incorrect position
        dropdown.css({
            bottom: '',
            left: '',
            top: '',
            right: ''
        }).removeClass('hover-top');

        var viewport = {
            top: win.scrollTop(),
            left: win.scrollLeft()
        };
        viewport.right = viewport.left + win.width();
        viewport.bottom = viewport.top + win.height();

        var bounds = dropdown.offset();
        if (bounds === undefined) {
            // fallback hack
            side = 'right';
            return side;
        }
        bounds.right = bounds.left + dropdown.outerWidth();
        bounds.bottom = bounds.top + dropdown.outerHeight();
        var position = dropdown.position();
        position.right = bounds.left + dropdown.outerWidth();
        position.bottom = bounds.top + dropdown.outerHeight();

        var side = '';

        var isSubnow = dropdown.parents('.dropdown-menu').length;

        if (isSubnow) {

            if (position.left < 0) {
                side = 'left';
                dropdown.removeClass('hover-right').addClass('hover-left')
            } else {
                side = 'right';
                dropdown.addClass('hover-right').removeClass('hover-left')
            }

            if (bounds.left < viewport.left) {
                side = 'right';
                dropdown.css({
                    left: '100%',
                    right: 'auto'
                }).addClass('hover-right').removeClass('hover-left')
            } else if (bounds.right > viewport.right) {
                side = 'left';
                dropdown.css({
                    left: 'auto',
                    right: '100%'
                }).removeClass('hover-right').addClass('hover-left')
            }

            if (bounds.bottom > viewport.bottom) {
                dropdown.css({
                    bottom: 'auto',
                    top: -(bounds.bottom - viewport.bottom)
                })
            } else if (bounds.top < viewport.top) {
                dropdown.css({
                    bottom: -(viewport.top - bounds.top),
                    top: 'auto'
                })
            }

        } else { // defines special position styles for root dropdown menu

            var parentLi = dropdown.parent('.dropdown');
            var pBounds = parentLi.offset();
            pBounds.right = pBounds.left + parentLi.outerWidth();
            pBounds.bottom = pBounds.top + parentLi.outerHeight();

            if (bounds.right > viewport.right) {
                var styleTmp = dropdown.attr('style');
                // keep css if "auto !important" is used
                if (
                    styleTmp &&
                    (
                        styleTmp.indexOf('left: auto !important;') === -1 &&
                        styleTmp.indexOf('right: auto !important;') === -1
                    )
                ) {
                    dropdown.css({
                        left: -(bounds.right - viewport.right),
                        right: 'auto'
                    });
                }
            }

            if (
                bounds.bottom > viewport.bottom &&
                (pBounds.top - viewport.top) > (viewport.bottom - pBounds.bottom) ||
                dropdown.position().top < 0
            ) {
                side = 'top';
                dropdown.css({
                    bottom: '100%',
                    top: 'auto'
                }).addClass('hover-top').removeClass('hover-bottom')
            } else {
                side = 'bottom';
                dropdown.addClass('hover-bottom')
            }
        }

        return side;

    };

    // DROPDOWNHOVER PLUGIN DEFINITION
    // ==========================

    /**
     * @param option
     *
     * @returns {*}
     *
     * @constructor
     */
    function Plugin(option) {
        return this.each(function() {
            var $this = $(this);
            var data = $this.data('bs.hover');
            var settings = $this.data();

            if ($this.data('animations') !== undefined && $this.data('animations') !== null) {
                settings.animations = $.isArray(settings.animations) ? settings.animations : settings.animations.split(' ');
            }

            var options = $.extend({}, Hover.DEFAULTS, settings, typeof option == 'object' && option);

            if (!data) {
                $this.data('bs.hover', (data = new Hover(this, options)));
            }

        })
    }

    var old = $.fn.hover;

    $.fn.hover = Plugin;
    $.fn.hover.Constructor = Hover;

    // DROPDOWNHOVER NO CONFLICT
    // ====================
    $.fn.hover.noConflict = function() {
        $.fn.hover = old;
        return this
    };

    // APPLY TO STANDARD DROPDOWNHOVER ELEMENTS
    // ===================================
    $(document).ready(function() {
        $('[data-hover="dropdown"], .navbar-nav').each(function() {
            var $target = $(this);
            if ('ontouchstart' in document.documentElement) {
                Plugin.call($target, $.extend({}, $target.data(), {
                    onClick: true
                }))
            } else {
                Plugin.call($target, $target.data())
            }
        })
    })

	//Customized Navbar
 	var $siteHeader = $(".site-header"),
		$navbar = $siteHeader.find('.navbar'),
		$navCollapse = $siteHeader.find('.navbar-collapse'),
		$window = $(window), 
		$html = $('html'),
		$body = $('body'),
		$navbarBreakPoint = 991;

    // Navbar sticky
    AdjustHeader();
    $window.scroll(function() {
        AdjustHeader();
    });

    function AdjustHeader() {
        var winWidth = window.innerWidth || $window.width();
        var scrollTop = 40;
        if (winWidth <= $navbarBreakPoint) {
            scrollTop = 40;
        }
        if ($window.scrollTop() > scrollTop) {
            if (!$navbar.hasClass("navbar-sticky")) {
                $navbar.addClass("navbar-sticky");
            }
        } else {
            $navbar.removeClass("navbar-sticky");
        }
    }

    // Navbar scroll
    var scPos = { newPos: 0, prevPos: 0 };
    window.addEventListener('scroll', SiteHeaderThrottle(scrollSiteHeader, 50));
    function SiteHeaderThrottle(fn, wait) {
        var time = Date.now();
        return function() {
            if ((time + wait - Date.now()) < 0) {
                fn();
                time = Date.now();
            }
        }
    }
    function scrollSiteHeader() {
        var YOffset = window.pageYOffset || document.documentElement.scrollTop;
        scPos.newPos = YOffset;
        if (scPos.newPos > scPos.prevPos && (scPos.newPos > 200 && scPos.prevPos > 200)) {
            $navbar.addClass('navbar-scroll');
        } else {
            $navbar.removeClass('navbar-scroll');
        }
        scPos.prevPos = YOffset;
    }

    // Navbar offcanvas
	$('[data-toggle="offcanvas"]').click(function() {
		$navCollapse.toggleClass('in');
		$body.toggleClass("navbar-open");
		$navbar.removeClass('navbar-scroll');
		if ($navCollapse.attr('aria-expanded') == 'true') {
            $navCollapse.attr('aria-expanded', 'false');
        } else {
            $navCollapse.attr('aria-expanded', 'true');
        }
		$('[data-toggle="offcanvas"]').each(function(){
			$(this).toggleClass('collapsed');
			if ($(this).attr('aria-expanded') == 'true') {
				$(this).attr('aria-expanded', 'false');
			} else {
				$(this).attr('aria-expanded', 'true');
			}
		});
	});
	
    // Bootstrap fix multilevel dropdown 
    $siteHeader.find('.dropdown').on({
        "click": function(event) {
            if ($(event.target).closest('.dropdown-toggle').length && $(this).parents('.dropdown').length === ($(event.target).parents('.dropdown').length - 1)) {
                $(this).data('closable', true);
            } else {
                $(this).data('closable', false);
            }
        },
        "hide.bs.dropdown": function(event) {
            var hide = $(this).data('closable');
            $(this).data('closable', true);
            return hide;
        }
    });
	
}(jQuery);